<template>
  <div>
    <template v-for="(groupOferta, index) in agrupamento.groupOfertas">
      <v-row :key="groupOferta.configuracaoFrete.id">
      <v-col cols="12" sm="7">
        <v-list class="px-4 pt-8 pb-8">
          <template v-for="(oferta) in groupOferta.ofertas">
            <px-carrinho-empresa-agrupamento-item class="mb-4"
                                                  :key="oferta.oferta.id"
                                                  :oferta="oferta"
                                                  :has-ipi="hasAnyIpi"
                                                  @change="emitChange"/>
          </template>
        </v-list>
      </v-col>
      <v-col cols="12" sm="5" class="pt-10">
        <div class="body-1 font-weight-bold">
          Opções de frete
        </div>
        <div>
          <px-carrinho-oferta-agrupamento-frete
            :agrupamento="agrupamento"
            :fornecedor="fornecedor"
            :company="company"
            :grupo-oferta="groupOferta"
            :configuracao-frete="groupOferta.configuracaoFrete"
            :total-produtos-a-vista="totalGrupoProdutosAVista(groupOferta)"
            :total-produtos-a-prazo="totalGrupoProdutosAPrazo(groupOferta)"/>
        </div>
      </v-col>
    </v-row>
      <v-divider class="px-8" :key="`div-${groupOferta.configuracaoFrete.id}`"
                 v-if="index !== (agrupamento.groupOfertas.length - 1)"/>
    </template>
    <div>
      <px-carrinho-empresa-agrupamento-totais @finalizar="onClickFinalize"
                                              :company="company"
                                              :fornecedor="fornecedor"
                                              :agrupamento="agrupamento"
                                              :loading="loading"
                                              :total-produtos-a-vista="totalProdutosAVista"
                                              :total-produtos-a-prazo="totalProdutosAPrazo"/>
    </div>
  </div>
</template>

<script>
import CarrinhoCalculoService from '@/modules/carrinho/carrinho-calculo-service';
import CarrinhoEmpresaAgrupamentoItem from './CarrinhoEmpresaAgrupamentoItem.vue';
import CarrinhoEmpresaAgrupamentoTotais from './CarrinhoEmpresaAgrupamentoTotais.vue';
import CarrinhoOfertaAgrupamentoFrete from './CarrinhoOfertaAgrupamentoFrete.vue';

export default {
  props: {
    agrupamento: {
      type: Object,
      required: true,
    },
    company: {
      type: Object,
      required: true,
    },
    fornecedor: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    totalGrupoProdutosAVista() {
      return grupo => CarrinhoCalculoService.calcularTotal(grupo.ofertas, 'precoProdutoAVista', 'impostoTotalAVista');
    },
    totalGrupoProdutosAPrazo() {
      return grupo => CarrinhoCalculoService.calcularTotal(grupo.ofertas, 'precoProdutoAPrazo', 'impostoTotalAPrazo');
    },
    totalProdutosAVista() {
      return CarrinhoCalculoService.calcularTotal(this.agrupamento.ofertas, 'precoProdutoAVista', 'impostoTotalAVista');
    },
    totalProdutosAPrazo() {
      return CarrinhoCalculoService.calcularTotal(this.agrupamento.ofertas, 'precoProdutoAPrazo', 'impostoTotalAPrazo');
    },
    hasAnyIpi() {
      return this.agrupamento.ofertas
        .some(carrinhOferta => carrinhOferta.oferta.oferta.ipi
          && carrinhOferta.oferta.oferta.ipi.destacar);
    },
  },
  methods: {
    emitChange() {
      this.$emit('change');
    },
    onClickFinalize(item) {
      this.$emit('finalizar', item);
    },
  },
  components: {
    pxCarrinhoEmpresaAgrupamentoItem: CarrinhoEmpresaAgrupamentoItem,
    pxCarrinhoEmpresaAgrupamentoTotais: CarrinhoEmpresaAgrupamentoTotais,
    pxCarrinhoOfertaAgrupamentoFrete: CarrinhoOfertaAgrupamentoFrete,
  },
};
</script>
