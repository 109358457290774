<template>
  <v-container class="mkt-content-container mt-10">
    <v-row class="ml-5 headline text--primary font-weight-bold">
      <v-col cols="12" sm="6">
        Meu carrinho
      </v-col>
      <v-col cols="12" sm="6" class="text-right">
        <px-carrinho-stepper :step="step" @change="onChangeStep"/>
      </v-col>
    </v-row>
    <v-divider class="mt-5 mb-12 bigger-divider"/>
    <div v-if="loading">
      <px-circular-loading/>
    </div>
    <div v-if="!loading && carrinho">
      <div v-if="!carrinho || !carrinho.length" align="center"
           class="headline mb-5">
        Ainda não há nenhum item no carrinho
        <div class="mt-8">
          <v-btn large color="primary" link to="/home"
                 class="rounded-card-small normal-btn-text">
            Continuar comprando
          </v-btn>
        </div>
      </div>
      <div v-show="isStepCarrinho">
        <div v-if="!loading && carrinho && carrinho.length">
          <v-alert
            class="rounded-card-small mb-10"
            color="primary">
            <div class="font-weight-bold mt-4 mb-4 white--text d-flex justify-center">
              Aqui você finaliza seu pedido para cada fornecedor de forma separada
            </div>
          </v-alert>
        </div>
        <px-carrinho-empresa v-for="item in carrinho"
                             :key="item.company.id"
                             :empresa-carrinho="item"
                             class="mt-3"
                             @finalizar="onClickFinalize"
                             @change="onChange"/>
      </div>
      <div v-if="isStepFinalizacao && itemFinalizacao">
        <px-carrinho-empresa-finalizar :item-finalizacao="itemFinalizacao" @back="onBack"/>
      </div>
    </div>
  </v-container>
</template>

<script>
import {
  chain, first, map,
} from 'lodash';
import CarrinhoEmpresa from './CarrinhoEmpresa.vue';
import CarrinhoService from './carrinho-service';
import CarrinhoStepper from './CarrinhoStepper.vue';
import CarrinhoEmpresaFinalizar from './CarrinhoEmpresaFinalizar.vue';

export default {
  data() {
    return {
      loading: false,
      carrinho: null,
      step: 0,
      itemFinalizacao: null,
      yPosition: 0,
    };
  },
  computed: {
    isStepCarrinho() {
      return this.step === 0;
    },
    isStepFinalizacao() {
      return this.step === 1;
    },
  },
  methods: {
    onChangeStep(step) {
      this.step = step;
    },
    onBack() {
      this.step = 0;
    },
    onClickFinalize(item) {
      this.step = 1;
      this.itemFinalizacao = item;
    },
    onChange() {
      this.getCarrinho();
    },
    mapAgrupamentoOferta(value) {
      return {
        ...value,
        groupOfertas: chain(value.ofertas)
          .groupBy('configuracaoFrete.id')
          .map(ofertaGroup => ({
            configuracaoFrete: first(ofertaGroup).configuracaoFrete,
            ofertas: ofertaGroup,
            frete: null,
          }))
          .value(),
      };
    },
    groupCarrinho(data) {
      return chain(data)
        .groupBy('company.id')
        .map(value => ({
          company: first(value).company,
          agrupamentos: map(value, this.mapAgrupamentoOferta),
        }))
        .value();
    },
    setScrollPosition() {
      if (window.scrollY) {
        this.yPosition = window.scrollY;
        return;
      }
      this.yPosition = 0;
    },
    resetScrollPosition() {
      if (this.yPosition) {
        window.scrollTo(0, this.yPosition);
      }
    },
    getCarrinho() {
      this.setScrollPosition();
      this.loading = true;
      CarrinhoService.getCarrinho()
        .then(({ data }) => {
          this.carrinho = this.groupCarrinho(data);
          setTimeout(() => {
            this.resetScrollPosition();
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    step(newValue, oldValue) {
      if (newValue === oldValue) {
        return;
      }
      if (newValue === 0) {
        this.itemFinalizacao = null;
      }
    },
  },
  created() {
    this.getCarrinho();
  },
  components: {
    pxCarrinhoEmpresa: CarrinhoEmpresa,
    pxCarrinhoStepper: CarrinhoStepper,
    pxCarrinhoEmpresaFinalizar: CarrinhoEmpresaFinalizar,
  },
};
</script>
