<template>
  <v-row dense class="pt-4 pb-4">
    <v-col cols="12" sm="6">
      <v-btn outlined
             large
             color="primary"
             class="normal-btn-text rounded-card-small"
             @click="emitBack">
        Voltar
      </v-btn>
    </v-col>
    <v-col cols="12" sm="6" class="text-sm-right">
      <v-btn large
             color="success"
             class="elevation-0 normal-btn-text rounded-card-small"
             :loading="loading"
             @click="onClickFinalizar">
        Finalizar pedido
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
    },
  },
  methods: {
    emitBack() {
      this.$emit('back');
    },
    onClickFinalizar() {
      this.$emit('finalizar');
    },
  },
};
</script>
