<template>
  <v-card outlined class="rounded-card border-color-primary">
    <v-card-text class="text--primary">
      <div class="title font-weight-bold pt-6 pl-6 pb-8">
        {{ itemFinalizacao.carrinho.company.pessoa.nome }}
      </div>
      <v-card class="mb-12 rounded-card card-shadow">
        <v-card-text class="text--primary pa-0">
          <div class="pa-6">
            <span class="body-2 font-weight-bold text--primary--color">
              {{ itemFinalizacao.carrinho.configuracaoVenda.fornecedor.pessoa.nome }}
              <px-open-pagina-fornecedor
                :id="itemFinalizacao.carrinho.configuracaoVenda.fornecedor.id"/>
            </span>
          </div>
          <px-carrinha-empresa-finalizar-detalhes :item="itemFinalizacao" @back="onBack"/>
        </v-card-text>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import CarrinhaEmpresaFinalizarDetalhes from './CarrinhaEmpresaFinalizarDetalhes.vue';

export default {
  props: {
    itemFinalizacao: {
      type: Object,
      required: true,
    },
  },
  methods: {
    onBack() {
      this.$emit('back');
    },
  },
  components: {
    pxCarrinhaEmpresaFinalizarDetalhes: CarrinhaEmpresaFinalizarDetalhes,
  },
};
</script>
