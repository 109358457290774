<template>
  <div>
    <v-skeleton-loader type="list-item@2"
                       :loading="loading"
    >
      <div>
        <px-carrinho-opcoes-frete :agrupamento="agrupamento"
                                  :configuracao-frete="configuracaoFrete"
                                  @change="onChangeFrete"/>
        <v-row>
          <v-col cols="12">
            <px-carrinho-frete-progress-bar v-if="freteCep"
                                            :fornecedor="fornecedor"
                                            :frete-original="freteCep"
                                            :total-produtos-a-vista="totalProdutosAVista"
                                            :total-produtos-a-prazo="totalProdutosAPrazo"/>
          </v-col>
        </v-row>
      </div>
    </v-skeleton-loader>
  </div>
</template>

<script>
import CarrinhoFreteProgressBar from '@/modules/carrinho/CarrinhoFreteProgressBar.vue';
import OfertaService from '@/modules/ofertas/oferta-service';
import CarrinhoOpcoesFrete from './CarrinhoOpcoesFrete.vue';
import CarrinhoCalculoService from './carrinho-calculo-service';

export default {
  props: {
    agrupamento: {
      type: Object,
      required: true,
    },
    configuracaoFrete: {
      type: Object,
      required: true,
    },
    grupoOferta: {
      type: Object,
      required: true,
    },
    company: {
      type: Object,
      required: true,
    },
    fornecedor: {
      type: Object,
      required: true,
    },
    totalProdutosAPrazo: {
      type: [Number],
      required: true,
    },
    totalProdutosAVista: {
      type: [Number],
      required: true,
    },
  },
  data() {
    return {
      freteCep: null,
      loading: false,
    };
  },
  computed: {
    totalFrete() {
      return CarrinhoCalculoService.calculaFrete(this.freteCep, this.totalProdutosAPrazo);
    },
  },
  methods: {
    onChangeFrete(frete) {
      this.grupoOferta.frete = frete;
    },
    getFreteCep() {
      this.loading = true;
      OfertaService.getFreteCep(this.configuracaoFrete.id, this.company.endereco.cep)
        .then(({ data }) => {
          this.freteCep = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getFreteCep();
  },
  components: {
    pxCarrinhoOpcoesFrete: CarrinhoOpcoesFrete,
    pxCarrinhoFreteProgressBar: CarrinhoFreteProgressBar,
  },
};
</script>
