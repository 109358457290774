<template>
  <div>
    <div v-if="!modoPagamentoEnabled" class="body-2 red--text">
      Mínimo de {{ modoPagamento.valorMinimo | currency }}
    </div>
    <div class="body-2">
      {{ modoPagamento.modoPagamento.observacao }}
    </div>
    <div class="body-1 font-weight-bold mt-1 text--primary">
      {{ modoPagamento.modoPagamento.descricao }}
    </div>
    <div>
      <v-icon x-large class="mt-2">
        {{ modoPagamento.modoPagamento.icon }}
      </v-icon>
    </div>
    <slot name="after"/>
  </div>
</template>

<script>
export default {
  props: {
    modoPagamento: {
      type: Object,
      required: true,
    },
    modoPagamentoEnabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
};
</script>
