<template>
  <v-list-item>
    <v-list-item-avatar width="80"
                        height="80"
                        class="square-avatar">
      <px-oferta-foto max-height="80"
                      max-width="80"
                      class="d-flex align-center justify-center"
                      :foto="oferta.oferta.oferta.foto"/>
    </v-list-item-avatar>
    <template>
      <v-list-item-content>
        <div>
          <router-link target="_blank"
                       :to="`/oferta/${oferta.oferta.oferta.id}`"
                       class="body-2">
            {{ oferta.oferta.oferta.titulo }}
          </router-link>
          <div class="mt-1" v-if="oferta.oferta.variacoes && oferta.oferta.variacoes.length">
            <px-show-variacoes-completo :is-small="false"
                                        :variacoes="oferta.oferta.variacoes"/>
          </div>
        </div>
        <div class="mt-1 body-2">
          Quantidade:
          <b>{{ oferta.quantidade }} {{ oferta.oferta.oferta.unidadeMedida.descricao }}</b>
          <px-carrinho-empresa-agrupamento-item-editar v-if="showControls"
                                                       :oferta="oferta"
                                                       @change="onChangeOferta"/>
        </div>
        <div class="mt-1 body-2">
          <div>
            Preço unit.:
            <b>{{ oferta.precoProdutoAVista | currency }}</b>
            <span v-if="showPrecoAPrazo">
              ou {{ oferta.precoProdutoAPrazo | currency }} a prazo
            </span>
          </div>
        </div>
        <div class="mt-1 body-2" v-if="hasIpi">
          <px-carrinho-oferta-ipi :oferta-carrinho="oferta" small/>
        </div>
        <div class="mt-1 body-1">
          <px-carrinho-oferta-total :oferta-carrinho="oferta" small/>
        </div>
      </v-list-item-content>
    </template>
    <v-list-item-action v-if="showControls">
      <v-btn icon :loading="loading" @click="onRemove()">
        <v-icon>mdi-delete-outline</v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { mapActions } from 'vuex';
import { GET_ITENS, REMOVE_ITEM } from '@/store/carrinho/actions.type';
import GtmService from '@/api/gtm-service';
import CarrinhoOfertaIpi from './CarrinhoOfertaIpi.vue';
import CarrinhoEmpresaAgrupamentoItemEditar from './CarrinhoEmpresaAgrupamentoItemEditar.vue';
import CarrinhoOfertaTotal from './CarrinhoOfertaTotal.vue';

export default {
  props: {
    oferta: Object,
    showControls: {
      type: Boolean,
      default: true,
    },
    hasIpi: {
      type: Boolean,
      default: false,
    },
    priceClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    showPrecoAPrazo() {
      return this.oferta.precoProdutoAVista !== this.oferta.precoProdutoAPrazo;
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  methods: {
    emitChange() {
      this.$emit('change');
    },
    ...mapActions('carrinho', {
      getItens: GET_ITENS,
      removeItem: REMOVE_ITEM,
    }),
    sendGAEvent() {
      return {
        currency: 'BRL',
        items: [{
          item_id: this.oferta.oferta.id,
          item_name: this.oferta.oferta.oferta.titulo,
          price: this.oferta.precoProdutoAVista,
          currency: 'BRL',
          quantity: this.oferta.quantidade,
        }],
        value: this.oferta.quantidade * this.oferta.precoProdutoAVista,
      };
    },
    onRemove() {
      this.loading = true;
      this.removeItem({ id: this.oferta.id })
        .then(() => {
          GtmService.buildPush('remove_from_cart', this.sendGAEvent);
          this.emitChange();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onChangeOferta() {
      this.emitChange();
    },
  },
  components: {
    pxCarrinhoEmpresaAgrupamentoItemEditar: CarrinhoEmpresaAgrupamentoItemEditar,
    pxCarrinhoOfertaIpi: CarrinhoOfertaIpi,
    pxCarrinhoOfertaTotal: CarrinhoOfertaTotal,
  },
};
</script>

<style>
.item-produto-title {
  min-width: 30%;
  padding-right: 12px;
}
</style>
