<template>
  <v-card outlined class="rounded-card border-color-primary">
    <v-card-text class="text--primary">
      <div class="title font-weight-bold pt-6 pl-6 pb-8">
        {{ empresaCarrinho.company.pessoa.nome }}
      </div>
      <v-card class="mb-12 rounded-card card-shadow"
              v-for="item in empresaCarrinho.agrupamentos"
              :key="item.configuracaoVenda.id">
        <v-card-text class="text--primary pa-0">
          <div class="pa-6">
            <span class="body-2 font-weight-bold text--primary--color">
              {{ item.configuracaoVenda.fornecedor.pessoa.nome }}
              <px-open-pagina-fornecedor :id="item.configuracaoVenda.fornecedor.id"/>
            </span>
          </div>
          <px-carrinho-empresa-agrupamento :agrupamento="item"
                                           :company="empresaCarrinho.company"
                                           :fornecedor="item.configuracaoVenda.fornecedor"
                                           @finalizar="onClickFinalize"
                                           @change="onChange"/>
        </v-card-text>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import CarrinhoEmpresaAgrupamento from './CarrinhoEmpresaAgrupamento.vue';

export default {
  props: {
    empresaCarrinho: Object,
  },
  data() {
    return {};
  },
  methods: {
    onChange() {
      this.$emit('change');
    },
    onClickFinalize(item) {
      this.$emit('finalizar', item);
    },
  },
  components: {
    pxCarrinhoEmpresaAgrupamento: CarrinhoEmpresaAgrupamento,
  },
};
</script>
