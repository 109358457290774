import currency from 'currency.js';
import { TipoFrete } from 'px-business-components';

function calcularTotal(ofertas, path, impostoPath) {
  const precos = ofertas.map(oferta => ({
    valor: oferta[path],
    imposto: oferta[impostoPath],
    quantidade: oferta.quantidade,
  }));
  let total = currency(0, { precision: 4 });
  precos.forEach((preco) => {
    const valorOferta = currency(preco.valor, { precision: 4 })
      .multiply(currency(preco.quantidade));
    total = total.add(valorOferta);

    if (preco.imposto) {
      total = total.add(preco.imposto);
    }
  });
  return total.value;
}

function calculaFreteCifFob(freteCep, totalAVista) {
  if (freteCep.ofertaFreteGratis && totalAVista >= freteCep.taxaMinimaEntrega) {
    return {
      tipoFrete: TipoFrete.keys.CIF.key,
      valorFrete: 0,
    };
  }
  return {
    tipoFrete: TipoFrete.keys.FOB.key,
    valorFrete: 0,
  };
}

function calculaCif(freteCep, totalAVista) {
  if (freteCep.ofertaFreteGratis && totalAVista >= freteCep.taxaMinimaEntrega) {
    return {
      tipoFrete: TipoFrete.keys.CIF.key,
      valorFrete: 0,
    };
  }

  return {
    tipoFrete: TipoFrete.keys.CIF.key,
    valorFrete: freteCep.valorTaxaEntrega,
  };
}

function calculaFrete(freteCep, totalAVista) {
  if (freteCep.tipoFrete === TipoFrete.keys.FOB.key) {
    return calculaFreteCifFob(freteCep, totalAVista);
  }

  return calculaCif(freteCep, totalAVista);
}

function createFobFreteCep(freteCep) {
  let prazoEmbarque = 1;
  if (freteCep.tipoFrete === TipoFrete.keys.FOB.key) {
    // eslint-disable-next-line prefer-destructuring
    prazoEmbarque = freteCep.prazoEmbarque;
  }
  return {
    ...freteCep,
    ofertaFreteGratis: false,
    taxaMinimaEntrega: 0,
    valorTaxaEntrega: 0,
    tipoFrete: TipoFrete.keys.FOB.key,
    prazoEmbarque,
  };
}

export default {
  calcularTotal,
  calculaFrete,
  createFobFreteCep,
};
