<template>
  <div class="pt-4 pb-4 px-10">
    <template v-for="(groupOferta, index) in item.carrinho.groupOfertas">
      <v-row :key="groupOferta.configuracaoFrete.id">
        <v-col cols="12" sm="7">
          <v-list class="px-4 pt-8 pb-8">
            <template v-for="(oferta) in groupOferta.ofertas">
              <px-carrinho-empresa-agrupamento-item class="mb-3"
                                                    price-class="text-right"
                                                    :show-controls="false"
                                                    :key="oferta.oferta.id"
                                                    :has-ipi="hasAnyIpi"
                                                    :oferta="oferta"/>
            </template>
          </v-list>
        </v-col>
        <v-col cols="12" sm="5" class="pt-10">
          <div class="body-1 font-weight-bold">
            Frete
          </div>
          <div>
            <px-carrinho-empresa-agrupamento-frete
              :fornecedor="item.carrinho.configuracaoVenda.fornecedor"
              :frete-cep="groupOferta.frete"/>
          </div>
        </v-col>
      </v-row>
      <v-divider class="px-8" :key="`div-${groupOferta.configuracaoFrete.id}`"
                 v-if="index !== (item.carrinho.groupOfertas.length - 1)"/>
    </template>
    <div class="ma-4 mt-12">
      <px-carrinha-empresa-finalizar-detalhes-frete :item="item"/>
      <v-divider class="mt-4 mb-8"/>
      <px-carrinha-empresa-finalizar-detalhes-total :item="item"/>
      <px-carrinha-empresa-finalizar-detalhes-pagamento class="mt-8"
                                                        :item="item"
                                                        @back="onBack"/>
    </div>
  </div>
</template>

<script>
import CarrinhoEmpresaAgrupamentoItem from './CarrinhoEmpresaAgrupamentoItem.vue';
import CarrinhaEmpresaFinalizarDetalhesFrete from './CarrinhaEmpresaFinalizarDetalhesFrete.vue';
import CarrinhaEmpresaFinalizarDetalhesPagamento from './CarrinhaEmpresaFinalizarDetalhesPagamento.vue';
import CarrinhaEmpresaFinalizarDetalhesTotal
  from './CarrinhaEmpresaFinalizarDetalhesTotal.vue';
import CarrinhoEmpresaAgrupamentoFrete from './CarrinhoEmpresaAgrupamentoFrete.vue';

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hasAnyIpi() {
      return this.item.carrinho.ofertas
        .some(carrinhOferta => carrinhOferta.oferta.oferta.ipi
          && carrinhOferta.oferta.oferta.ipi.destacar);
    },
  },
  methods: {
    onBack() {
      this.$emit('back');
    },
  },
  components: {
    pxCarrinhoEmpresaAgrupamentoItem: CarrinhoEmpresaAgrupamentoItem,
    pxCarrinhaEmpresaFinalizarDetalhesFrete: CarrinhaEmpresaFinalizarDetalhesFrete,
    pxCarrinhaEmpresaFinalizarDetalhesPagamento: CarrinhaEmpresaFinalizarDetalhesPagamento,
    pxCarrinhaEmpresaFinalizarDetalhesTotal: CarrinhaEmpresaFinalizarDetalhesTotal,
    pxCarrinhoEmpresaAgrupamentoFrete: CarrinhoEmpresaAgrupamentoFrete,
  },
};
</script>
