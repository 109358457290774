<template>
  <div>
    <v-avatar color="primary" @click="emitClickStep">
      <v-btn icon>
        <v-icon color="white">
          mdi-cart-outline
        </v-icon>
      </v-btn>
    </v-avatar>
    <px-line-indicator :content-class="`mx-0 ${paymentColor}`"/>
    <v-avatar :class="paymentClass" :color="paymentColor">
      <v-icon :color="paymentIconColor">
        mdi-credit-card-outline
      </v-icon>
    </v-avatar>
  </div>
</template>

<script>
export default {
  props: {
    step: {
      type: Number,
      required: true,
    },
  },
  computed: {
    paymentClass() {
      if (this.step === 0) {
        return 'border-solid';
      }
      return '';
    },
    paymentColor() {
      if (this.step === 0) {
        return '';
      }
      return 'primary';
    },
    paymentIconColor() {
      if (this.step === 0) {
        return '';
      }
      return 'white';
    },
  },
  methods: {
    emitClickStep() {
      this.$emit('change', 0);
    },
  },
};
</script>
