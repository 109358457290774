<template>
  <div v-if="hasIpi">
    {{ percentual | number('0.00') }}%
    ({{ ofertaCarrinho.impostoTotalAVista | currency }})
    <span v-if="small">de IPI</span>
    <div class="body-2" v-if="!small">
      de IPI
    </div>
  </div>
</template>

<script>
import { NumberUtils } from 'px-components';

export default {
  props: {
    ofertaCarrinho: {
      type: Object,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasIpi() {
      return this.ofertaCarrinho.oferta.oferta.ipi
        && this.ofertaCarrinho.oferta.oferta.ipi.destacar;
    },
    percentual() {
      if (!this.hasIpi) {
        return null;
      }
      return NumberUtils.fixNumberInput(this.ofertaCarrinho.oferta.oferta.ipi.percentualImposto,
        true);
    },
  },
};
</script>
